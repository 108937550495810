import liff from '@line/liff';
import { Link, useLocation } from '@remix-run/react';
import { useEffect, useState, type FunctionComponent, type ReactNode } from 'react';
import { useLoginQueryParameter } from '~/hooks/useLoginQueryParameter';
import { useLiffContext } from '~/providers';

type LinkType = 'openLine' | 'liff' | 'browser';

type LineLoginLinkProps = {
  callbackPath: string;
  className?: string;
  onClick?: () => void;
  children: ReactNode;
};
const LineLoginLink: FunctionComponent<LineLoginLinkProps> = (props) => {
  const { callbackPath, className, onClick, children } = props;

  return (
    <Link to={callbackPath} className={className} onClick={onClick}>
      {children}
    </Link>
  );
};

type BrowserLoginLinkProps = {
  className?: string;
  children: ReactNode;
  browserLogin: () => void;
};
const BrowserLoginLink: FunctionComponent<BrowserLoginLinkProps> = (props) => {
  const { className, children, browserLogin } = props;

  return (
    <span onClick={browserLogin} className={className} style={{ cursor: 'pointer' }}>
      {children}
    </span>
  );
};

type Props = {
  children: ReactNode;
  className?: string;
  redirectPath?: string;
  isNewRegister?: boolean;
  action?: string;
  forceLiffLogin?: boolean;
  onClick?: () => void;
};

export const LoginLink: FunctionComponent<Props> = (props) => {
  const {
    children,
    className,
    redirectPath: designatedRedirectPath,
    isNewRegister,
    action,
    forceLiffLogin,
    onClick
  } = props;
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;
  const redirectPath = designatedRedirectPath || currentPath;
  const { loginQueryParameter } = useLoginQueryParameter({ redirectPath, action, loginSourcePath: currentPath });
  const callbackPath = `/callback?${loginQueryParameter}`;
  const openLinePath = `/open_line?${loginQueryParameter}`;
  const [linkType, setLinkType] = useState<LinkType>('openLine');
  const { liffId } = useLiffContext();

  useEffect(() => {
    if (liff.isInClient()) {
      setLinkType('liff');
      return;
    }

    if (liff.getOS() === 'web') {
      setLinkType('browser');
      return;
    }
  }, []);

  const browserLogin = () => {
    const currentOrigin = window.location.origin;
    liff.login({ redirectUri: `${currentOrigin}${callbackPath}` });
  };

  if (isNewRegister) {
    if (linkType === 'liff') {
      return (
        <LineLoginLink callbackPath={callbackPath} className={className} onClick={onClick}>
          {children}
        </LineLoginLink>
      );
    }

    if (linkType === 'browser') {
      return (
        <BrowserLoginLink browserLogin={browserLogin} className={className}>
          {children}
        </BrowserLoginLink>
      );
    }

    if (forceLiffLogin) {
      return (
        <a href={`https://liff.line.me/${liffId}${callbackPath}`} className={className}>
          {children}
        </a>
      );
    }

    return (
      <Link to={openLinePath} className={className} onClick={onClick}>
        {children}
      </Link>
    );
  } else {
    if (linkType === 'liff') {
      return (
        <LineLoginLink callbackPath={callbackPath} className={className} onClick={onClick}>
          {children}
        </LineLoginLink>
      );
    }

    return (
      <BrowserLoginLink browserLogin={browserLogin} className={className}>
        {children}
      </BrowserLoginLink>
    );
  }
};
